
































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import {imageUrl, showTagRE} from '@/utils/common'
import {ExportExcel} from "@/utils/exportFile";
import store from "@/store";

@Component({})
export default class AUser extends Vue {
  baseUrl = imageUrl

  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    keyword: '',
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };

  exportD() {
    api.getN('J27533?token=' + store.state.user.token + '&keyword=' + this.search.keyword + '&acceptId=' + this.$route.query.acceptId).then(res => {
      // 假设 response 是后端返回的包含数据流的响应对象
      // 创建blob对象，解析流数据
      const blob = new Blob([res], {
        // 设置返回的文件类型
        type: 'application/octet-stream'
      })
      // 这里就是创建一个a标签，等下用来模拟点击事件
      const a = document.createElement('a')
      const URL = window.URL || window.webkitURL
      const herf = URL.createObjectURL(blob)
      a.href = herf
      a.download = '【订单'+new Date().toLocaleString() +'】.xlsx'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(herf)

    })
  }


  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post('J27530', {...this.search, acceptId: this.$route.query.acceptId})
      .then(res => {
        this.total = res.total;
        this.tableData = res.list;
      }).finally(() =>{
      this.loading = false;
    })
  }

  // 编辑
  toEdit(row: any) {
    this.$router.push({
      name: 'activityAdd',
      query:{
        activityNumber: row.activityNumber
      }
    })
  }

  activated() {
    this.searchTable();
  }
}
